import { useState, FC, useEffect, useRef, useContext } from 'react';
import I18n from '../../helpers/i18n';
import DirectusManager from '../../helpers/directusManager';
import { UserContext } from '../../context/userContext';
import Wrapper from '../../components/wrapper';
import Snackbar from '../../components/snackbar';
import Table from '../../components/tables/overview-table-full';

interface Props {}

const headerData = [
    {
        Header: I18n.t('ID'),
        accessor: 'col0',
        type: 'status',
        Link: false,
    },
    {
        Header: I18n.t('TABLE_ORDER_DATE'),
        accessor: 'col1',
        type: 'status',
        Link: false,
    },
    {
        Header: I18n.t('TABLE_USER'),
        accessor: 'col2',
        type: 'code',
        Link: false,
    },
    {
        Header: I18n.t('TABLE_TOTAL'),
        accessor: 'col3',
        type: 'status',
        Link: false,
    },
    {
        Header: I18n.t('TABLE_GAININGS'),
        accessor: 'col4',
        type: 'status',
        Link: false,
    },
    {
        Header: I18n.t('TABLE_PRODUCT_NAME'),
        accessor: 'col5',
        type: 'code',
        Link: false,
    },
];

const Purchases: FC<Props> = (props) => {
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState<any>([]);
    const [pageCount, setPageCount] = useState<number>(1);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const snackBar = useRef();
    const userContext = useContext(UserContext);

    useEffect(() => {
        getData();
    }, []);

    const changePage = (page: number) => {
        getData(page + 1);
        setCurrentPage(page + 1);
    };

    const getData = async (page?: number) => {
        var sort: any = ['-date_created'];
        var fields: any = [
            'id',
            'status',
            'product_name',
            'wp_user_email',
            'date_created',
            'subtotal',
            'commission',
            'customer',
        ];

        const paymentsData = DirectusManager.shared.directus.items('payments');
        const customersData = DirectusManager.shared.directus.items('customers');

        paymentsData
            .readByQuery({ fields: fields, meta: 'filter_count', sort: sort, limit: 10, page: page ?? 1 })
            .then(async (payments) => {
                if (payments !== undefined && payments.data !== undefined && payments.data !== null) {
                    let paymentsMapped = [];

                    for (const y of payments.data) {
                        const customer: any = await customersData.readOne(y.customer);

                        const date = new Date(y.date_created);
                        const formattedDate = [
                            (date.getMonth() + 1).toString().padStart(2, '0'),
                            date.getDate().toString().padStart(2, '0'),
                            date.getFullYear(),
                        ].join('-');

                        paymentsMapped.push({
                            id: y.id,
                            col0: y.id,
                            col1: formattedDate,
                            col2: customer?.name ?? y.wp_user_email,
                            col3: y.subtotal.toFixed(2),
                            col4: ((y.subtotal * y.commission) / 100).toFixed(2),
                            col5: y.product_name,
                        });
                    }

                    setData(paymentsMapped);
                    if (payments.meta?.filter_count !== undefined) {
                        var totalPages = Math.ceil(payments.meta?.filter_count / 20);
                        setPageCount(totalPages);
                    }
                }
            })
            .catch((error) => {
                // Sentry.captureException(error);
                console.log(error.message);
            });
    };

    return (
        <Wrapper title={I18n.t('PURCHASES')} public={false}>
            <Table
                allowExport={false}
                title={I18n.t('PURCHASES')}
                showActions={false}
                allowNew={false}
                type='payments'
                loading={loader}
                columns={{
                    headings: headerData,
                }}
                data={data}
                pageCount={pageCount}
                currentPage={currentPage}
                changePage={(page: number) => changePage(page)}
            />

            <Snackbar ref={snackBar} />
        </Wrapper>
    );
};

export default Purchases;
