import React, { FC, useEffect, useState } from 'react';
import I18n from '../../helpers/i18n';
import Chart from './chart';

interface Props {
    title: string;
    data: {
        couponsCreatedThisMonth: number;
        couponsUsedThisMonth: number;
        couponsCreated: number;
        couponsUsed: number;
        monthlyProfitMoney: number;
        totalProfitMoney: number;
        monthlyProfitCounter: number;
        totalProfitCounter: number;
        monthlyPaid: number;
        totalPaid: number;
    };
    downloadButton: any;
}

const Statics: FC<Props> = (props) => {
    // const chartDataTotal = {
    //     labels: [I18n.t('CHART_PENDING'), I18n.t('CHART_CREATED'), I18n.t('CHART_RECEIVED'), I18n.t('CHART_RENEWAL')],
    //     datasets: [
    //         {
    //             data: [
    //                 props.data.totalPending,
    //                 props.data.totalDone,
    //                 props.data.totalConfirmed,
    //                 props.data.totalRenewal,
    //             ],
    //             backgroundColor: ['#5ac2cf', '#4e7795', '#de994e', '#e56366', '#f7f7f7'],
    //         },
    //     ],
    // };

    /*
        In case we want display a pie chart with the data by months, we have to return 
        the correct data from the endpoint and change the values here
    */
    // const chartDataMonthly = {
    //     labels: ['Total', I18n.t('STATUS_VALIDATED')],
    //     datasets: [
    //         {
    //             data: [props.data.totalReferralsM, props.data.totalReferralsValidatedM],
    //             backgroundColor: ['#5ac2cf', '#4e7795'],
    //         },
    //     ],
    // };

    // const getData = (type: string) => {
    //     if (type === 'chartDataTotal') {
    //         chartDataTotal.datasets.forEach((dataset) => {
    //             if (dataset.data.every((el: any) => el === 0)) {
    //                 dataset.backgroundColor.push('rgba(255,255,255,1)');
    //                 dataset.data.push(1);
    //             }
    //         });
    //         return chartDataTotal;
    //     } else if (type === 'chartDataMonthly') {
    //         chartDataMonthly.datasets.forEach((dataset) => {
    //             if (dataset.data.every((el: any) => el === 0)) {
    //                 dataset.backgroundColor.push('rgba(255,255,255,1)');
    //                 dataset.data.push(1);
    //             }
    //         });
    //         return chartDataMonthly;
    //     }
    //     return false;
    // };

    return (
        <div className='home-dashboard'>
            <div className='d-flex justify-content-between align-items-center header'>
                <h3 className='title'>{props.title}</h3>
                {/* {props.downloadButton} */}
            </div>
            <div className='container-fluid d-flex justify-content-between align-items-center p-0 m-0'>
                <div className='row w-100 p-0 m-0'>
                    {/* <div className="chart-child">
                        <h5 className="sub-title">{I18n.t('MONTHLY_RATE')}</h5>
                        <hr />
                        <Chart data={getData('chartDataMonthly')} />
                    </div> */}

                    <div className='col-12 col-lg-6 mb-4'>
                        <div className='statistics-block'>
                            <div className='sub-child'>
                                <h5 className='sub-title'>{I18n.t('COUPONS_USED_MONTH')}</h5>
                                <hr />
                                <div className='amount-text'>{props.data.couponsUsedThisMonth}</div>
                            </div>
                            <div className='sub-child'>
                                <h5 className='sub-title'>{I18n.t('COUPONS_CREATED_MONTH')}</h5>
                                <hr />
                                <div className='amount-text'>{props.data.couponsCreatedThisMonth}</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6 mb-4'>
                        <div className='statistics-block'>
                            <div className='sub-child'>
                                <h5 className='sub-title'>{I18n.t('COUPONS_USED_TOTAL')}</h5>
                                <hr />
                                <div className='amount-text'>{props.data.couponsUsed}</div>
                            </div>
                            <div className='sub-child'>
                                <h5 className='sub-title'>{I18n.t('COUPONS_CREATED_TOTAL')}</h5>
                                <hr />
                                <div className='amount-text'>{props.data.couponsCreated}</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6 mb-4'>
                        <div className='statistics-block'>
                            <div className='sub-child'>
                                <h5 className='sub-title'>{I18n.t('TOTAL_PROFIT_MONTHLY')}</h5>
                                <hr />
                                <div className='amount-text'>${props.data.monthlyProfitMoney.toFixed(2)}</div>
                            </div>
                            <div className='sub-child'>
                                <h5 className='sub-title'>{I18n.t('TOTAL_PROFIT')}</h5>
                                <hr />
                                <div className='amount-text'>${props.data.totalProfitMoney.toFixed(2)}</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6 mb-4'>
                        <div className='statistics-block'>
                            <div className='sub-child'>
                                <h5 className='sub-title'>{I18n.t('MONTHLY_PURCHASES')}</h5>
                                <hr />
                                <div className='amount-text'>{props.data.monthlyProfitCounter}</div>
                            </div>
                            <div className='sub-child'>
                                <h5 className='sub-title'>{I18n.t('TOTAL_PURCHASES')}</h5>
                                <hr />
                                <div className='amount-text'>{props.data.totalProfitCounter}</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6 mb-4'>
                        <div className='statistics-block'>
                            <div className='sub-child'>
                                <h5 className='sub-title'>{I18n.t('TOTAL_VALIDATED_REFERRALS')}</h5>
                                <hr />
                                <div className='amount-text'>{props.data.totalPaid}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Statics;
