import { FC, useState, useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { UserContext } from '../context/userContext';

import PageBehaviour from './page_behaviour';

import Four0Four from '../pages/404';
import LoginPage from '../pages/login';
import HomePage from '../pages/home';

import Referrals from '../pages/referrals/overview';
import ReferralDetail from '../pages/referrals/detail';
import NewReferral from '../pages/referrals/new';

import Customers from '../pages/customers/overview';
import NewCustomer from '../pages/customers/new';
import CustomerDetail from '../pages/customers/detail';

import EmailTemplate from '../pages/email-template';
import ValidateInvoice from '../pages/validate-invoice';
import { PrivateRoute } from './privateRouter';
import Auth from '../pages/auth';

import ForgotPassword from '../pages/forgotpassword';
import ResetPassword from '../pages/resetpassword';
import NewPassword from '../pages/new-password';

import ProfitList from '../pages/profit-list';
import Purchases from '../pages/purchases';

const Navigation: FC = (props) => {
    const context = useContext(UserContext);
    const [auth, setAuth] = useState(false);

    return (
        <UserContext.Provider value={context}>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <PageBehaviour />
                <Routes>
                    <Route
                        path='/'
                        element={
                            <PrivateRoute auth={auth}>
                                <HomePage />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/referrals'
                        element={
                            <PrivateRoute auth={auth}>
                                <Referrals />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/referrals/:id'
                        element={
                            <PrivateRoute auth={auth}>
                                <ReferralDetail />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/referrals/new'
                        element={
                            <PrivateRoute auth={auth}>
                                <NewReferral />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/customers'
                        element={
                            <PrivateRoute auth={auth}>
                                <Customers />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/customers/:id'
                        element={
                            <PrivateRoute auth={auth}>
                                <CustomerDetail />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/customers/new'
                        element={
                            <PrivateRoute auth={auth}>
                                <NewCustomer />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/purchases'
                        element={
                            <PrivateRoute auth={auth}>
                                <Purchases />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/profit-list'
                        element={
                            <PrivateRoute auth={auth}>
                                <ProfitList />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path='/email-template'
                        element={
                            <PrivateRoute auth={auth}>
                                <EmailTemplate />
                            </PrivateRoute>
                        }
                    />
                    <Route path='/login' element={<LoginPage />} />
                    <Route path='/validate/:id' element={<ValidateInvoice />} />
                    <Route path='/forgot-password' element={<ForgotPassword />} />
                    <Route path='/reset-password/:token' element={<ResetPassword />} />
                    <Route path='/new-password/:token' element={<NewPassword />} />
                    <Route path='/auth' element={<Auth auth={auth} setAuth={(state: boolean) => setAuth(state)} />} />
                    <Route path='*' element={<Four0Four />} />
                </Routes>
            </BrowserRouter>
        </UserContext.Provider>
    );
};

export default Navigation;
