import React, { useState, FC, useEffect, forwardRef, useImperativeHandle } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../button';
import I18n from '../../helpers/i18n';
import OnOutsideClick from 'react-outclick';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface Props {
    visible: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
    title: string;
    text: string;
    confirmText?: string;
    cancelText?: string;
    warning?: boolean;
    defaultStyle?: boolean;
    value?: string;
    validation?: boolean;
    onCopy?: () => void;
    onSuccessCopy?: string;
}

const Alert: FC<Props> = (props, ref) => {
    return props.visible ? (
        <div className='alert-wrapper d-flex align-items-center justify-content-center'>
            <OnOutsideClick
                onOutsideClick={(ev: Event) => {
                    props.onCancel && props.onCancel();
                }}
            >
                <div className='block'>
                    <div className='close-icon' onClick={() => props.onCancel && props.onCancel()}>
                        <CloseIcon />
                    </div>
                    <span className='title'>{props.title}</span>
                    <span className='text'>{props.text}</span>
                    {props.value && props.value !== undefined ? (
                        <div className='sub' onClick={props.onCopy}>
                            <span className='span-value'>{props.value}</span>
                            <div className='copy-icon'>
                                <ContentCopyIcon />
                            </div>
                        </div>
                    ) : (
                        <div></div>
                    )}
                    {props.onSuccessCopy && props.onSuccessCopy !== '' ? (
                        <div className='successcopy'>{props.onSuccessCopy}</div>
                    ) : (
                        <div className='successcopy'></div>
                    )}
                    <div className='btn-container d-flex justify-content-between'>
                        {props.confirmText ? (
                            <Button
                                title={props.confirmText}
                                defaultStyle={props.defaultStyle ? true : false}
                                warningStyle={props.warning ? true : false}
                                fullwidth
                                onPress={() => props.onConfirm && props.onConfirm()}
                            />
                        ) : (
                            <div></div>
                        )}

                        {props.cancelText ? (
                            <>
                                <div className='btn-spacing' />
                                <Button
                                    title={props.cancelText}
                                    cancelStyle
                                    fullwidth
                                    onPress={() => props.onCancel && props.onCancel()}
                                />
                            </>
                        ) : (
                            <div></div>
                        )}
                    </div>
                </div>
            </OnOutsideClick>
        </div>
    ) : null;
};
export default Alert;
