import axios from 'axios';
import React, { useState, FC, useEffect, useContext } from 'react';
import Wrapper from '../../components/wrapper';
import DirectusManager from '../../helpers/directusManager';
import { useNavigate } from 'react-router-dom';
import I18n from '../../helpers/i18n';
import Statics from '../../components/statics';
import Button from '../../components/button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { UserContext } from '../../context/userContext';
import * as Sentry from '@sentry/react';

const HomePage: FC = (props) => {
    const navigate = useNavigate();
    const userContext = useContext(UserContext);
    const [loaderButton, setLoaderButton] = useState(false);

    const [data, setData] = useState({
        couponsCreatedThisMonth: 0,
        couponsUsedThisMonth: 0,
        couponsCreated: 0,
        couponsUsed: 0,
        monthlyProfitMoney: 0,
        totalProfitMoney: 0,
        monthlyProfitCounter: 0,
        totalProfitCounter: 0,
        monthlyPaid: 0,
        totalPaid: 0,
        // rateM: 0,
    });

    useEffect(() => {
        if (DirectusManager.shared.isLoggedIn() == false) {
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        let supplier_id = userContext.supplier_id;
        const suppliersManager = DirectusManager.shared.directus.items('suppliers');
        const commission = await suppliersManager
            .readOne(supplier_id, { fields: ['*.*'] })
            .then((s) => {
                return s.commission;
            })
            .catch((error) => {
                Sentry.captureException(error);
                console.log(error.message);
            });

        if (!commission) return;

        const token = await DirectusManager.shared.directus.auth.token;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                auth_token: DirectusManager.shared.directus.auth.token,
                supplier_id: supplier_id,
            }),
        };

        fetch(DirectusManager.shared.baseUrl + '/referral-data', requestOptions)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                if (response !== undefined) {
                    setData({
                        couponsCreatedThisMonth: response.couponsCreatedThisMonth ?? 0,
                        couponsUsedThisMonth: response.couponsUsedThisMonth ?? 0,
                        couponsCreated: response.couponsCreated ?? 0,
                        couponsUsed: response.couponsUsed ?? 0,
                        monthlyProfitMoney: response.monthlyProfitMoney ?? 0,
                        totalProfitMoney: response.totalProfitMoney ?? 0,
                        monthlyProfitCounter: response.monthlyProfitCounter ?? 0,
                        totalProfitCounter: response.totalProfitCounter ?? 0,
                        monthlyPaid: response.monthlyPaid ?? 0,
                        totalPaid: response.totalPaid ?? 0,
                        // rateM: Math.round((totalValidatedMonthly / totalMonthlyReferrals) * 100),,
                    });
                }
            })
            .catch((error) => {
                Sentry.captureException(error);
                console.log(error);
            });
    };

    // const exportStatics = async () => {
    //     setLoaderButton(true);
    //     let supplier_id = userContext.supplier_id;
    //     let token = await DirectusManager.shared.directus.auth.token;
    //     var data = JSON.stringify({
    //         supplier_id: supplier_id.toString(),
    //         auth_token: token,
    //     });

    //     var config = {
    //         method: 'post',
    //         url: DirectusManager.shared.baseUrl + '/export-supplier-statics/',
    //         headers: {
    //             Authorization: 'Bearer ' + token,
    //             'Content-Type': 'application/json',
    //         },
    //         data: data,
    //     };

    //     axios(config)
    //         .then(function (response: any) {
    //             window.open(response.data.data.url + '?download=&access_token=' + token);
    //         })
    //         .catch(function (error) {
    //             Sentry.captureException(error);
    //             console.log(error);
    //         })
    //         .finally(() => {
    //             setLoaderButton(false);
    //         });
    // };

    return (
        <Wrapper title={I18n.t('HOME_DASHBOARD')} public={false}>
            <div className='dashboard-wrapper'>
                <Statics
                    title={I18n.t('STATICS')}
                    data={data}
                    downloadButton={
                        <Button
                            defaultStyle
                            title={I18n.t('EXPORT_STATICS')}
                            icon={<FileDownloadIcon />}
                            // onPress={() => exportStatics()}
                            loader={loaderButton}
                        />
                    }
                />
            </div>
        </Wrapper>
    );
};

export default HomePage;
